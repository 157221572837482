import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Link from "../utils/Link"
import Image from "../utils/Image"

import Box from "../atoms/Box"
import Button from "../atoms/Button"
import Card from "../atoms/Card"
import Flex from "../atoms/Flex"

import SectionTitle from "../molecules/SectionTitle"

const StyledCard = styled(Card)`
  &:hover {
    .card-back-reveal {
      opacity: 1;
    }
  }
`

const StyledLink = styled(Link)`
  &:hover {
    .card-front-logo {
      ${props => (props.zoomOnHover ? "transform:scale(1.05)" : null)}
    }
  }
`

const LogoBox = ({ image, cardFront, imgMaxHeight, ...rest }) => {
  const border = {}
  if (!image) {
    //show empty box if there is not logo
    border.borderColor = "border.0"
    border.borderWidth = "0.5px"
    border.borderStyle = "solid"
  }
  return (
    <Flex
      className="card-front-logo"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      {...rest}
    >
      <Box
        transitionProperty="transform"
        transitionTimingFunction="ease"
        transitionDuration="md"
        minHeight={4}
        width="100%"
        height="100%"
        {...border}
      >
        {image && (
          <Image
            {...image}
            style={{ height: "100%" }}
            imgStyle={{
              objectFit: "contain",
              height: "100%",
              width: "100%",
            }}
          />
        )}
      </Box>
    </Flex>
  )
}

const RevealBox = ({
  align,
  cardBack,
  button,
  sectionTitle,
  verticalAlign,
  ...rest
}) => {
  return (
    <Card {...cardBack} {...rest}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={verticalAlign === "center" ? "center" : "space-between"}
        alignItems={align === "center" ? "center" : "flex-start"} //alignment for button
        className="card-reveal"
        backgroundColor="inherit"
        position="absolute"
        top="0"
        width="100%"
        height="100%"
        p={5}
      >
        <Box maxHeight="calc(100% - 48px)" overflow="hidden">
          {sectionTitle && <SectionTitle {...sectionTitle} align={align} />}
        </Box>
        {button && <Button {...button} />}
      </Box>
    </Card>
  )
}

const LogoItem = ({
  image,
  cardFront,
  link,
  sectionTitle,
  button,
  cardBack,
  align,
  verticalAlign,
  zoomOnHover,
  shouldShowReveal = true,
  cardMaxHeight,
  customCardMaxHeight,
  logoBoxFrontCardPadding,
  ...rest
}) => {
  shouldShowReveal =
    shouldShowReveal && cardBack.useCard && (sectionTitle || button) // ||richText?.content

  if (!cardFront || !cardFront.useCard) {
    return (
      <Flex
        // alignItems="center"
        // p={{ _: 4, md: 5 }}
        p={{ _: 4 }}
        {...rest}
      >
        <StyledLink zoomOnHover={zoomOnHover} width="100%" {...link} optional>
          <LogoBox image={image} />
        </StyledLink>
      </Flex>
    )
  }

  return (
    <StyledLink
      className="logo-card-link"
      zoomOnHover={zoomOnHover}
      width="100%"
      {...link}
      optional
    >
      <StyledCard
        {...cardFront}
        {...rest}
        ratio={
          cardMaxHeight === "fullHeight"
            ? 1
            : cardMaxHeight === "default"
            ? 0.7
            : 1
        }
        maxHeight={
          cardMaxHeight === "custom" && customCardMaxHeight > 0
            ? `${customCardMaxHeight}px`
            : 40
        }
      >
        <LogoBox
          // p={{ _: 4, md: 5, }}
          p={logoBoxFrontCardPadding}
          image={image}
          cardFront={cardFront}
        />
        {shouldShowReveal && (
          <RevealBox
            className="card-back-reveal"
            cardBack={cardBack}
            sectionTitle={sectionTitle}
            align={align}
            verticalAlign={verticalAlign}
            button={button}
            transitionDuration="fast"
            transitionTimingFunction="ease-in-out"
            transitionProperty="opacity"
            opacity={0}
          />
        )}
      </StyledCard>
    </StyledLink>
  )
}

export default LogoItem

export const query = graphql`
  fragment Logo on Strapi_ComponentMoleculesLogo {
    image {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 300, layout: CONSTRAINED)
        }
      }
    }
    sectionTitle {
      ...SectionTitle
    }
    button {
      ...Button
    }
    link {
      ...Link
    }
    color
  }
`
