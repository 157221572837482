import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import Icon from "../atoms/Icon"
import Box from "../atoms/Box"
import Flex from "../atoms/Flex"
import { Paragraph, Label, Title } from "../atoms/Typography"

const Quote = ({ quoteText, name, position, textAlign, logo, noMarginBottom, ...rest }) => {
  return (
    <Box mx={textAlign === "center" ? "auto" : 0} mt={4} {...rest}>
      {quoteText && (
        <Paragraph align={textAlign} mb={noMarginBottom === true ? 0 : 6}>
          {quoteText}
        </Paragraph>
      )}
      {name && (
        <Label align={textAlign} mb={3}>
          {name}
        </Label>
      )}
      {position && (
        <Paragraph align={textAlign} mb={logo && 4}>
          {position}
        </Paragraph>
      )}
      {logo && (
        <Flex maxWidth={7} alignItems="flex-start">
          <Icon {...logo} height="100%" />
        </Flex>
      )}
    </Box>
  )
}

export default Quote

Quote.strapiProps = {
  quoteText: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string,
  image: PropTypes.object,
}

export const query = graphql`
  fragment Quote on Strapi_ComponentMoleculesQuote {
    name
    position
    quoteText
    logo {
      ...Icon
    }
    image {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`
