import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"

import Card from "../atoms/Card" // import for propTypes
import Section from "../atoms/Section"
import FlexGrid from "../atoms/FlexGrid"

import FilteredGrid from "../molecules/FilteredGrid"

import SectionTitle from "../molecules/SectionTitle"
import CompanyCard from "../molecules/CompanyCard"

const PortfolioGrid = ({
  section,
  sectionTitle,
  flexGrid,
  companyCards,
  filter,
  card,
  portfolioGridImageLocation,
  imageLocation, // for component preview
}) => {
  const center = !sectionTitle || sectionTitle?.align === "center"

  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
        </Reveal>
      )}
      <FilteredGrid centerFilter={center} filter={filter} flexGrid={flexGrid}>
        {companyCards?.map((company, i) => {
          return (
            <CompanyCard
              sectionTheme={section.theme}
              key={`${company.name}-${i}`}
              forwardKey={`${company.name}`}
              {...company}
              imageLocation={portfolioGridImageLocation || imageLocation} // component preview
              card={card}
            />
          )
        })}
      </FilteredGrid>
    </Section>
  )
}

export default PortfolioGrid

PortfolioGrid.strapiProps = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  flexGrid: PropTypes.shape(FlexGrid.strapiProps),
  portfolioGridImageLocation: PropTypes.oneOf(["default", "top", "innerRight"]),
  card: PropTypes.shape(Card.strapiProps),
  companyCards: PropTypes.arrayOf(PropTypes.shape(CompanyCard.strapiProps)),
}

PortfolioGrid.propTypes = {
  ...PortfolioGrid.strapiProps,
}

export const query = graphql`
  fragment PortfolioGrid on Strapi_ComponentSectionsPortfolioGrid {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    flexGrid {
      ...FlexGrid
    }
    companyCards {
      ...CompanyCard
    }
    card {
      ...Card
    }
    filter {
      ...Filter
    }
    portfolioGridImageLocation: imageLocation
  }
`
