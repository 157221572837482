import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"
import Flex from "../atoms/Flex"
import Button from "../atoms/Button"

import SectionTitle from "../molecules/SectionTitle"

const Cta = ({ section, sectionTitle, button }) => {
  const left = sectionTitle.align === "left"

  return (
    <Section {...section}>
      <Reveal cascade>
        <Flex
          flexDirection={left ? { _: "column", md: "row" } : "column"}
          alignItems="center"
        >
          {sectionTitle && (
            <SectionTitle
              {...sectionTitle}
              //maxWidth={42}
              width={
                left && sectionTitle?.titleMaxWidth === null
                  ? { _: "100%", md: "60%" }
                  : null
              }
              mr={left ? { _: 0, md: 5 } : "auto"}
            />
          )}
          {button && (
            <Flex
              mt={left ? { _: 6, md: 0 } : 6}
              alignItems="center"
              justifyContent={left ? { _: "left", md: "flex-end" } : "center"}
              flexDirection="row"
              height="100%"
              width={left ? { _: "100%", md: "40%" } : "100%"}
              ml={left ? { _: 0, md: 5 } : null}
            >
              <Button {...button} mr={left ? { _: 0, md: 4 } : null} />
            </Flex>
          )}
        </Flex>
      </Reveal>
    </Section>
  )
}

Cta.propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  button: PropTypes.shape(Button.strapiProps).isRequired,
}

export default Cta

export const query = graphql`
  fragment Cta on Strapi_ComponentSectionsCta {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    button {
      ...Button
    }
  }
`
