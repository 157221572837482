import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import { Title, Label } from "../atoms/Typography"
import Box from "../atoms/Box"
import RichText from "../atoms/RichText"

const SectionTitle = ({
  title,
  titleColor,
  variant = "h2",
  as,
  label,
  descriptionRichText,
  align = "left",
  maxWidth,
  titleMaxWidth,
  ...rest
}) => {
  //return early if editor has no data to avoid adding unnecessary padding to the DOM
  const hasLabel = label && label?.length
  const hasTitle = title && title?.length
  const hasDescription = descriptionRichText?.content?.blocks[0]?.text?.length

  if (titleMaxWidth !== null && titleMaxWidth !== undefined) {
    switch (titleMaxWidth) {
      case "fullWidth":
        maxWidth = "100%"
        break
      case "wide":
        maxWidth = 56
        break
      default:
        maxWidth = 42 
    }
  }

  if (!hasLabel && !hasTitle && !hasDescription) return null

  return (
    <Box mx={align === "center" ? "auto" : ""} {...rest} maxWidth={maxWidth}>
      {hasLabel && (
        <Label mb={3} textAlign={align}>
          {label}
        </Label>
      )}
      {hasTitle && (
        <Title variant={variant} as={as} textAlign={align} color={titleColor}>
          {title}
        </Title>
      )}
      {descriptionRichText?.content && (
        <RichText mt={3} textAlign={align} {...descriptionRichText} />
      )}
    </Box>
  )
}

SectionTitle.strapiProps = {
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.oneOf(["text", "primary"]),
  variant: PropTypes.oneOf(["h1", "h2"]).isRequired,
  as: PropTypes.oneOf(["h1", "h2"]),
  label: PropTypes.string,
  descriptionRichText: PropTypes.object,
  align: PropTypes.oneOf(["left", "center"]).isRequired,
}

SectionTitle.propTypes = {
  ...SectionTitle.strapiProps,
  ...Box.propTypes,
}

export default SectionTitle

export const query = graphql`
  fragment SectionTitle on Strapi_ComponentMoleculesSectionTitle {
    title
    titleColor
    variant
    as
    label
    align
    titleMaxWidth
    descriptionRichText {
      ...RichText
    }
  }
`
