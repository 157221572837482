import React from "react"
import { graphql } from "gatsby"

import GenericImageCarousel from "../molecules/GenericImageCarousel"

const QuoteCarousel = ({
  section,
  sectionTitle,
  quotes,
  quoteCarouselImageLocation,
  imageLocation,
  showArrows,
  quoteCarouselShowArrows,
  showIndicators,
  quoteCarouselShowIndicators,
  controlsLocation,
  quoteCarouselControlsLocation,
  textAlign,
  quoteCarouselTextAlign,
  showImages,
  quoteCarouselShowImages,
  rotateSpeed,
  quoteCarouselRotateSpeed,
  card,
  icon,
}) => {
  return (
    <GenericImageCarousel
      section={section}
      sectionTitle={sectionTitle}
      items={quotes}
      card={card}
      icon={icon}
      imageLocation={imageLocation || quoteCarouselImageLocation}
      showImages={showImages || quoteCarouselShowImages}
      controlsLocation={controlsLocation || quoteCarouselControlsLocation}
      controls={showArrows || quoteCarouselShowArrows}
      indicators={showIndicators || quoteCarouselShowIndicators}
      rotateSpeed={rotateSpeed || quoteCarouselRotateSpeed}
      textAlign={textAlign || quoteCarouselTextAlign}
      quoteCarousel
    />
  )
}

export default QuoteCarousel

export const query = graphql`
  fragment QuoteCarousel on Strapi_ComponentSectionsQuoteCarousel {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    quotes {
      ...Quote
    }
    card {
      ...Card
    }
    icon {
      ...Icon
    }
    quoteCarouselImageLocation: imageLocation
    quoteCarouselControlsLocation: controlsLocation
    quoteCarouselShowImages: showImages
    quoteCarouselShowArrows: showArrows
    quoteCarouselShowIndicators: showIndicators
    quoteCarouselTextAlign: textAlign
    quoteCarouselRotateSpeed: rotateSpeed
  }
`
