import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"
import Image from "../utils/Image"

import Section from "../atoms/Section"
import StandardPadding from "../atoms/StandardPadding"
import Box from "../atoms/Box"

import SectionTitle from "../molecules/SectionTitle"
import Flex from "../atoms/Flex"

const ImageSection = ({ section, sectionTitle, image, width }) => {
  let padded, regular, extended, fullWidth
  let boxProps = {}
  switch (width) {
    case "padded":
      boxProps.mx = { _: 0, sm: 8, md: 10 }
      padded = true
      break
    case "regular":
      regular = true
      break
    case "extended":
      extended = true
      break
    case "fullWidth":
      fullWidth = true
      break
    default:
      padded = true
  }

  const fullWidthAndTitle = fullWidth && sectionTitle
  return (
    <Section
      {...section}
      backgroundImageMobile={
        fullWidthAndTitle ? image : section.backgroundImageMobile
      }
      backgroundImageDesktop={
        fullWidthAndTitle ? image : section.backgroundImageDesktop
      }
      verticalPadding={fullWidth ? null : section.verticalPadding}
      noStandardPadding={fullWidth}
    >
      {fullWidthAndTitle && (
        <Flex
          minHeight={{ _: "60vw", lg: "40vw" }}
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <StandardPadding
            py={{ _: 9, md: 11 }}
            maxWidth={55}
            zIndex={10}
            px={0}
          >
            <Box
              bg="rgba(255,255,255,0.5)"
              py={{ _: 7, md: 9 }}
              px={{ _: 6, md: 8 }}
            >
              <SectionTitle {...sectionTitle} maxWidth={42} />
            </Box>
          </StandardPadding>
        </Flex>
      )}
      {fullWidth && !sectionTitle && (
        <Image
          {...image}
          style={{ width: "100%", maxHeight: "40vw" }}
          imgStyle={{ objectFit: "cover" }}
        />
      )}
      {!fullWidth && (
        <StandardPadding
          maxWidth={extended ? 81 : 70}
          verticalPadding={section.verticalPadding}
          px={0}
        >
          {sectionTitle && (
            <Reveal>
              <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
            </Reveal>
          )}
          {image && (
            <Reveal>
              <Box {...boxProps}>
                <Image
                  {...image}
                  style={{ width: "100%" }}
                  imgStyle={{ objectFit: "cover" }}
                />
              </Box>
            </Reveal>
          )}
        </StandardPadding>
      )}
    </Section>
  )
}

ImageSection.strapiProps = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps),
  image: PropTypes.object,
  width: PropTypes.oneOf(["padded", "regular", "extended", "fullWidth"]),
}

ImageSection.propTypes = {
  ...ImageSection.strapiProps,
}

export default ImageSection

export const query = graphql`
  fragment ImageSection on Strapi_ComponentSectionsImage {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    image {
      url
      alternativeText
      imageFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    width
  }
`
