import React from "react"
import { graphql } from "gatsby"

import Layout from "../page/Layout"
import SEO from "../page/Seo"
import Sections from "../page/Sections"

const Page = ({ data }) => {
  const site = data.strapi.site
  const page = data.strapi.site.pages[0]

  let lastSectionStyling = {
    theme: "default",
    bg: 0,
  }
  for (let i = page.sections.length - 1; i >= 0; i--) {
    let currSection = page.sections[i]
    if (currSection.section && !currSection.section.hidden) {
      lastSectionStyling.theme = currSection.section.theme
      lastSectionStyling.bg = currSection.section.bg
      lastSectionStyling.sectionBreak = currSection.section.sectionBreak
      break
    }
  }
  let prevSection = null
  let visibleSections = page.sections.filter(sec => !sec.section?.hidden)
  return (
    <Layout lastSectionStyling={lastSectionStyling} site={site}>
      <SEO
        socialShare={site.socialShareImage}
        favicon={site.favicon}
        metaDescription={site.siteDescription}
        siteTitle={site.title}
        title={page.title}
        keywords={page.keywords}
        pageDescription={page.pageDescription}
      />

      {visibleSections.map((section, i) => {
        const key = `${section.__typename}_${section.id}`
        const totalItems = visibleSections.length - 1
        prevSection = visibleSections[i - 1]?.section

        const firstSection = i === 0
        const lastSectionHasBreak =
          section?.section?.sectionBreak === "slope" && i === totalItems
        const prevSectionHasBreak =
          !firstSection && prevSection && prevSection?.sectionBreak === "slope"

        const sectionPreviewStyling = {
          zIndex: totalItems - i + 1,
          mt: prevSectionHasBreak ? "-81px !important" : null, //hide white space between sections
          pt: prevSectionHasBreak ? `calc(81px + 80px) !important` : null,
          pb: section?.section?.sectionBreak ? `80px ` : null,
          mb: lastSectionHasBreak ? "80px !important" : null,
        }

        return (
          <Sections
            section={{
              ...section,
              section: {
                ...section.section,
                prevSection: prevSection,
                ...sectionPreviewStyling,
              },
            }}
            key={key}
          />
        )
      })}
    </Layout>
  )
}

export default Page

export const query = graphql`
  fragment Page on Strapi_Page {
    title
    slug
    keywords
    pageDescription
    sections {
      ...Sections
    }
  }
`
