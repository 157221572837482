import React, { useState } from "react"

import Reveal from "../utils/Reveal"

import FlexGrid from "../atoms/FlexGrid"
import Filter from "../atoms/Filter"
import Flex from "../atoms/Flex"

const FilteredGrid = ({ centerFilter, filter, flexGrid, children }) => {
  const [filteredChildrenArr, setFilteredChildrenArr] = useState(
    React.Children.toArray(children)
  )

  const childrenArr = React.Children.toArray(children)

  return (
    <div>
      {filter && (
        <Reveal>
          <Flex justifyContent={centerFilter ? "center" : "flex-start"} mb={6}>
            <Filter
              center={centerFilter}
              onChange={(tag) => {
                if (!tag) setFilteredChildrenArr(childrenArr)
                else
                  setFilteredChildrenArr(
                    childrenArr.filter(
                      (child) =>
                        child.props?.tags
                          ?.map(({ name }) => name)
                          .indexOf(tag) > -1
                    )
                  )
              }}
              filterItems={children}
              {...filter}
            />
          </Flex>
        </Reveal>
      )}
      <FlexGrid {...flexGrid}>
        {filteredChildrenArr.map((child, i) => (
          <div key={`${child.props.forwardKey}-${i}`}>{child}</div>
        ))}
      </FlexGrid>
    </div>
  )
}

export default FilteredGrid
