import * as yup from "yup"
import slugify from "slugify"
import { set } from "lodash"

/**
 * Adds a field to a Yup schema
 * https://github.com/jquense/yup/issues/559
 * @param {Object} schema - the schema to add to
 * @param {Object} config - data fir the new field
 * @returns
 */
export const createYupSchema = (schema, config) => {
  const { name, validationType, validations = [] } = config
  if (!yup[validationType]) {
    return schema
  }
  let validator = yup[validationType]()
  validations.forEach(validation => {
    const { params, type } = validation
    if (!validator[type]) {
      return
    }
    validator = validator[type](...params)
  })
  schema[name] = validator
  return schema
}

/**
 * Takes input data from Strapi and generates ids and validations
 * @param {Object[]} inputs
 * @returns
 */
export const processInputs = inputs => {
  return inputs.map(input => {
    let newInput = { ...input, validations: [] }
    newInput.name =
      input.name || slugify(input.label, { lower: true, strict: true })

    // Select validationType ("string" | "number" | "date" | "object" | "boolean", etc)
    switch (input.type) {
      case "text":
      case "email":
      case "url":
      case "multiline":
      case "select":
        newInput.validationType = "string"
        break
      default:
    }

    // Add specific validations
    if (input.required) {
      newInput.validations.push({
        type: "required",
        params: ["This field is required"],
      })
    }
    switch (input.type) {
      case "email":
        newInput.validations.push({
          type: "email",
          params: ["Please enter a valid email"],
        })
        break
      case "url":
        newInput.validations.push({
          type: "url",
          params: ["Please enter a valid url"],
        })
        break
      default:
    }

    return newInput
  })
}

export const getValidationSchema = inputs => {
  const yupSchema = inputs.reduce(createYupSchema, {})
  const validationSchema = yup.object().shape(yupSchema)
  return validationSchema
}

export const getInitialValues = inputs => {
  let initialValues = {}
  inputs.forEach(input => {
    set(initialValues, input.name, "")
  })
  return initialValues
}
