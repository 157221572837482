import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import Box from "../atoms/Box"
import SectionTitle from "../molecules/SectionTitle"
import Button from "../atoms/Button"

const ImageTextCarouselItem = ({ text, button, textAlign, ...rest }) => {
  return (
    <Box mx={textAlign === "center" ? "auto" : 0} {...rest}>
      {text && <SectionTitle {...text} align={textAlign} as="p" mb={4} />}
      <Box align={textAlign}>{button && <Button {...button} />}</Box>
    </Box>
  )
}

export default React.memo(ImageTextCarouselItem)

ImageTextCarouselItem.strapiProps = {
  text: PropTypes.string,
  button: PropTypes.object,
  image: PropTypes.object,
}

export const query = graphql`
  fragment ImageTextCarouselItem on Strapi_ComponentMoleculesImageTextCarouselItem {
    button {
      ...Button
    }
    text {
      ...SectionTitle
    }
    image {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    backgroundImage {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
    backgroundImageMobile {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
  }
`
