import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"

import Image from "../utils/Image"
import Link from "../utils/Link"

import Card from "../atoms/Card"
import Flex from "../atoms/Flex"
import Box from "../atoms/Box"
import { Title, Paragraph } from "../atoms/Typography"

const NameBox = ({ name, positionTitle, textClassName, center, ...rest }) => {
  return (
    <Box
      display={center ? "flex" : "block"}
      flexDirection="column"
      justifyContent="center"
      width="100%"
      textAlign={center ? "center" : "left"}
      className="name-box"
      {...rest}
    >
      <Title className={textClassName} variant="h3" mb={2}>
        {name}
      </Title>
      {positionTitle && (
        <Paragraph className={textClassName}>{positionTitle}</Paragraph>
      )}
    </Box>
  )
}

const StyledCard = styled(Card)`
  .name-box {
    opacity: ${props => (props.$showNameOnHover ? 0 : 1)};
    transition-property: opacity;
  }
  &:hover {
    .name-box {
      opacity: 1;
    }
  }
`

const StyledOverlayImage = styled(Image)`
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.34, 0.61, 0.48, 0.94);
  will-change: opacity;
  &:hover {
    opacity: 0;
  }
`

const CircleLink = styled(Link)`
  .name {
    transition: opacity 0.3s ease;
    will-change: opacity;
  }
  &:hover .name {
    opacity: 0.6;
  }
  & .image img {
    transition: transform 0.3s ease;
    will-change: transform;
  }
  &:hover .image img {
    transform: scale(1.08);
  }
`

const CardLink = styled(Link)`
  .name {
    transition: opacity 0.4s ease;
    will-change: opacity;
  }
  &:hover .name {
    opacity: 0.6;
  }
  .image {
    transition: transform 0.4s ease;
    will-change: transform;
  }
  &:hover .image {
    transform: scale(1.05);
  }
`

const TeamCard = ({
  name,
  positionTitle,
  image,
  overlayImage,
  card,
  imageShape,
  link,
  showNameOnHover,
  align,
}) => {
  const square = imageShape === "square"
  const circle = imageShape === "circle"

  const center = align === "center"

  if (circle) {
    return (
      <CircleLink optional {...link}>
        <Card boxShadow="none" bg="transparent" overflow="visible">
          <Flex flexDirection={center ? "column" : "row"} alignItems="center">
            {image && (
              <Box flex="0 0 auto" mb={center ? 4 : 0} height={6} width={6}>
                <Image
                  className="image"
                  {...image}
                  style={{
                    height: "100%",
                    borderRadius: "50%",
                    willChange: "transform",
                    margin: "0.5px",
                  }}
                  imgStyle={{
                    borderRadius: "50%",
                  }}
                />
              </Box>
            )}
            <NameBox
              center={center}
              className="name"
              name={name}
              positionTitle={positionTitle}
              ml={!center && image && 4}
            />
          </Flex>
        </Card>
      </CircleLink>
    )
  }

  return (
    <CardLink optional {...link}>
      <StyledCard
        ratio={square ? 1 : 0.85}
        $showNameOnHover={showNameOnHover}
        {...card}
      >
        {image && (
          <Image
            className="image"
            {...image}
            style={{
              height: "calc(100% - 80px)",
            }}
          />
        )}
        {overlayImage && (
          <StyledOverlayImage
            className="image"
            {...overlayImage}
            style={{
              height: "calc(100% - 80px)",
              position: "absolute",
              zIndex: 100,
              top: 0,
            }}
          />
        )}
        <NameBox
          textAlign={center ? "center" : "left"}
          textClassName="name"
          name={name}
          positionTitle={positionTitle}
          position="absolute"
          height={5}
          width="100%"
          bottom="0"
          padding={4}
          transitionDuration="fast"
          transitionTimingFunction="ease"
        />
      </StyledCard>
    </CardLink>
  )
}

export default TeamCard

TeamCard.strapiProps = {
  name: PropTypes.string.isRequired,
  positionTitle: PropTypes.string,
  image: PropTypes.object,
}

TeamCard.propTypes = {
  ...TeamCard.strapiProps,
  imageShape: PropTypes.oneOf(["default", "square", "circle"]),
  showNameOnHover: PropTypes.bool,
  card: PropTypes.shape(Card.strapiProps),
  link: PropTypes.shape(Link.strapiProps),
}

export const query = graphql`
  fragment TeamCard on Strapi_ComponentMoleculesTeamCard {
    name
    positionTitle
    image {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    overlayImage {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    link {
      ...Link
    }
  }
`
