import React, { useState } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import { useAlert } from "react-alert"
import {
  processInputs,
  getInitialValues,
  getValidationSchema,
} from "../../lib/formik"
import { get } from "lodash"

import TextField from "../atoms/TextField"
import Select from "../atoms/Select"
import Button from "../atoms/Button"

const MorphicForm = ({ label, data }) => {
  const alert = useAlert()
  const [loading, setLoading] = useState(false)
  const isNetlify = !data.submit?.url

  const inputs = processInputs(data.inputs)

  const formik = useFormik({
    initialValues: getInitialValues(inputs),
    validationSchema: getValidationSchema(inputs),
    onSubmit: async (values, actions) => {
      values["form-name"] = label

      setLoading(true)
      const res = await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(values),
      })

      if (!res.ok) {
        alert.show("There was an error submitting the form", { type: "error" })
      } else {
        alert.show("Form submitted successfully!", { type: "success" })
        actions.resetForm()
      }
      setLoading(false)
    },
  })

  const formInputs = inputs.map((input, i) => {
    const name = input.name
    const touched = get(formik.touched, name)
    const error = get(formik.errors, name)
    const formikProps = {
      name: name,
      value: get(formik.values, name, ""),
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      error: touched && Boolean(error),
      helperText: touched && error,
    }
    switch (input.type) {
      case "text":
      case "email":
      case "url":
      case "multiline":
        return (
          <TextField
            id={name}
            label={input.label}
            required={input.required}
            multiline={input.type === "multiline"}
            rows={input.type === "multiline" ? 4 : undefined}
            width="100%"
            mb={4}
            key={i}
            {...formikProps}
          />
        )
      case "select":
        return (
          <Select
            id={name}
            label={input.label}
            options={input.options}
            required={input.required}
            width="100%"
            mb={4}
            key={i}
            {...formikProps}
          />
        )
      default:
        return null
    }
  })

  // Form with action URL
  if (!isNetlify) {
    return (
      <form action={data.submit.url} method="post" target="_blank">
        {formInputs}
        <Button type="submit" variant="primary">
          Submit
        </Button>
      </form>
    )
  }

  // Netlify Form
  return (
    <form
      onSubmit={formik.handleSubmit}
      data-netlify={isNetlify ? "true" : "false"}
      netlify-honeypot="bot-field"
      name={label}
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value={label} />
      {formInputs}
      <Button type="submit" loading={loading} variant="primary">
        Submit
      </Button>
    </form>
  )
}

MorphicForm.propTypes = {
  label: PropTypes.string,
  data: PropTypes.object,
}

export default MorphicForm
