import React, { useContext } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled, { ThemeContext } from "styled-components"
import { compose, layout } from "styled-system"
import { animation } from "../../lib/styled-system/system"

import Link from "../utils/Link"
import Image from "../utils/Image"
import { hexToRGBA } from "../../lib/color-helpers"

import Box from "../atoms/Box"
import Button from "../atoms/Button"
import Card from "../atoms/Card"
import ProportionalBox from "../atoms/ProportionalBox"
import { Label, Title } from "../atoms/Typography"
import RichText from "../atoms/RichText"
import Section from "../atoms/Section"
import LayoutChanger from "../atoms/LayoutChanger"

const TextBox = styled(
  ({
    title,
    label,
    cardTitleVariant,
    cardTitleColor,
    descriptionRichText,
    className,
    textClassName,
    textAlign,
    button,
    showTitle,
    layoutAlign,
    ...rest
  }) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems={textAlign === "center" ? "center" : "flex-start"}
        justifyContent="flex-start"
        className={className}
        {...rest}
      >
        {showTitle && (
          <Box
            className={textClassName}
            display="flex"
            alignItems={textAlign === "center" ? "center" : "flex-start"}
            flexDirection="column"
          >
            {label && <Label mb={3}>{label}</Label>}
            <Title
              textAlign={textAlign === "center" ? "center" : "left"}
              transitionProperty="opacity"
              transitionTimingFunction="ease"
              transitionDuration="md"
              variant={cardTitleVariant === "h1" ? "h1" : "h2"}
              as={cardTitleVariant === "h1" ? "h1" : "h2"}
              color={cardTitleColor}
              mb={3}
            >
              {title}
            </Title>
          </Box>
        )}
        <Box
          className={textClassName}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems={textAlign === "center" ? "center" : "flex-start"}
          height="100%"
        >
          {descriptionRichText?.content && (
            <StyledRichText
              transitionProperty="opacity"
              transitionTimingFunction="ease"
              transitionDuration="md"
              textAlign={textAlign}
              {...descriptionRichText}
            />
          )}
          {button && <Button mt={1} pb={4} {...button} />}
        </Box>
      </Box>
    )
  }
)`
  ${animation}
`

const TitleImageBox = ({
  title,
  label,
  cardTitleVariant,
  cardTitleColor,
  descriptionRichText,
  className,
  textClassName,
  textAlign,
  button,
  image,
  imageRatio,
  showText,
  showTitle,
  imageWidth,
  useCard,
  horizontalCard,
  showGradient,
  overlayOpacity,
  reversed,
  layoutAlign,
  ...rest
}) => {
  return (
    <Box
      display="flex"
      flexDirection={reversed ? "column-reverse" : "column"}
      alignItems={
        layoutAlign === "center"
          ? "center"
          : layoutAlign === "end"
          ? "flex-end"
          : "flex-start"
      } //align Title on vertical card
      width="100%"
      height="100%"
      className="imageTextbox"
      {...rest}
    >
      {image && (
        <ProportionalBox
          display="flex"
          flex={1.8}
          ratio={imageRatio === "taller" ? 1 : 0.75}
          className="image-container"
          transitionProperty="height, transform"
          transitionTimingFunction="ease"
          transitionDuration="md"
          overflow="hidden"
        >
          <Box
            className="image"
            height="100%"
            transitionProperty="height, transform"
            transitionTimingFunction="ease"
            transitionDuration="md"
          >
            <StyledImage
              {...image}
              style={{
                height: "100%",
                width: "100%",
              }}
              imgStyle={{
                padding: imageWidth === "full" || !useCard ? "0px" : "24px",
              }}
              minHeight={!useCard ? "358px" : "auto"}
            />
          </Box>
        </ProportionalBox>
      )}
      {showTitle && (
        <Box
          className={textClassName}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems={textAlign === "center" ? "center" : "flex-start"}
          p={5}
          pb={3}
          flex={0.2}
        >
          {label && <Label mb={3}>{label}</Label>}
          <Title
            transitionProperty="opacity"
            transitionTimingFunction="ease"
            transitionDuration="md"
            variant={cardTitleVariant === "h1" ? "h1" : "h2"}
            as={cardTitleVariant === "h1" ? "h1" : "h2"}
            color={cardTitleColor}
            mb={3}
          >
            {title}
          </Title>
        </Box>
      )}
    </Box>
  )
}

const StyledLink = styled(Link)`
  &:hover {
    & .image-container {
      //getting scale on image-container or image to avoid issue with image overflowing;
      ${props =>
        props.textBackground === "gradient" && props.imageWidth === "full"
          ? "transform: scale(1.05)"
          : null};

      & .image {
        ${props =>
          props.textBackground === "solid" || props.imageWidth === "default"
            ? "transform: scale(1.05)"
            : null};
      }
    }
  }
`

const StyledCard = styled(Card)`
  & .shaved-text {
    opacity: ${props => (props.textVisibility !== "onHover" ? 1 : 0)};
  }
  &:hover {
    & .shaved-text {
      ${props => (props.textVisibility === "onHover" ? "opacity: 1" : null)};
    }
    & .shaved-text {
      opacity: ${props => (props.textVisibility === "onHover" ? 1 : 0.75)};
    }
  }
`

const StyledRichText = styled(RichText)`
  &.shaved-text {
    & .RichEditor-unstyled {
      margin-bottom: 0px; // removing unwanted margin from richText
    }
  }
  ${animation}
`

const StyledImage = styled(Image)`
  ${compose(layout)}
`

const defaultCard = {
  borderRadius: "none",
  boxShadow: "md",
  bg: 0,
  theme: "light",
  useCard: false,
}

const FlexImageCard = ({
  title,
  label,
  descriptionRichText,
  image,
  link,
  alignText,
  card,
  cardTitle,
  textBackground,
  textVisibility,
  backgroundImageDesktop,
  backgroundImageMobile,
  button,
  overlayOpacity,
  imageWidth,
  imageRatio,
  layoutChanger,
  imagePosition = "default",
}) => {
  //setting a default value for card prop
  card =
    card && card.constructor === Object && Object.keys(card).length > 0
      ? card
      : defaultCard

  const currentTheme = useContext(ThemeContext)
  const bgColor = card
    ? currentTheme?.themes[card?.theme]?.colors?.background[card?.bg]
    : "#FFFFFF"

  const solidColorWithOpacity = hexToRGBA(bgColor, overlayOpacity * 100) //150)

  let showText = textVisibility !== "none" && card.useCard && bgColor
  let showGradient = textBackground === "gradient" && showText
  const hasDescription = descriptionRichText?.content?.blocks[0]?.text?.length
    ? true
    : false

  {
    /*TODO: create a wrapper for card background image indiviual instance to stop using Section comp*/
  }
  const horizontalCard =
    layoutChanger?.location === "left" || layoutChanger?.location === "right"
  const verticalCard =
    layoutChanger?.location === "top" || layoutChanger?.location === "bottom"
  const reversedCard =
    layoutChanger?.location === "top" && imagePosition === "center"
      ? true
      : false
  return (
    <StyledLink
      optional
      {...link}
      textBackground={textBackground}
      imageWidth={imageWidth}
    >
      <StyledCard
        {...card}
        bg={null} //avoiding card to set bg color
        position="relative"
        textVisibility={textVisibility}
        textBackground={textBackground}
        height="100%"
        border="none"
        ratio={horizontalCard && imageRatio === "taller" ? 1 : null}
      >
        <Section
          backgroundImageMobile={backgroundImageMobile}
          backgroundImageDesktop={backgroundImageDesktop}
          noStandardPadding={true}
          style={{ background: solidColorWithOpacity }} //passing overlay color with opacity to section to get rid of image overflow issue
          overlayOpacity={overlayOpacity}
          height="100%"
          transitionProperty="opacity,background"
          transitionTimingFunction="ease"
          transitionDuration="md"
        >
          <LayoutChanger
            itemsMoveStackedOnColumn={false}
            {...layoutChanger}
            child1Style={{
              mt: 0,
              alignItems: "flex-start",
              flex: verticalCard && imagePosition === "center" ? 600 : 1,
            }}
            child2Style={{
              alignSelf: horizontalCard ? "stretch" : "flex-start",
              flexDirection: verticalCard ? "column" : "row",
              ml: 0,
              mt: 0,
              mr: 0,
              justifyContent: "center",
              minHeight: horizontalCard ? 12 : 6,
              flex:
                verticalCard && imagePosition === "center"
                  ? "auto"
                  : verticalCard && imageRatio === "taller"
                  ? 1.3
                  : 1,
            }}
            height="100%"
            width="100%"
            display="flex"
            className="layout-changer"
            reversed={reversedCard}
          >
            {showText && (title || hasDescription) && (
              <TextBox
                showTitle={
                  horizontalCard || imagePosition !== "center" ? true : false
                }
                layoutAlign={layoutChanger?.align}
                transitionProperty="opacity"
                transitionTimingFunction="ease"
                transitionDuration="md"
                textAlign={alignText === "center" ? "center" : "left"}
                descriptionRichText={descriptionRichText}
                button={button}
                textClassName="shaved-text"
                className="text-box"
                title={title}
                label={label}
                {...cardTitle}
                height="100%"
                width="100%"
                pt={5}
                px={5}
              />
            )}
            <TitleImageBox
              showTitle={verticalCard && imagePosition === "center"}
              reversed={
                imagePosition === "center" && verticalCard ? true : false
              }
              horizontalCard={horizontalCard}
              showGradient={showGradient}
              overlayOpacity={overlayOpacity}
              showText={showText}
              useCard={card?.useCard}
              image={image}
              imageWidth={imageWidth}
              imageRatio={imageRatio}
              transitionProperty="opacity"
              transitionTimingFunction="ease"
              transitionDuration="md"
              textAlign={alignText}
              layoutAlign={layoutChanger?.align}
              descriptionRichText={descriptionRichText}
              button={button}
              textClassName="shaved-text"
              className="text-box"
              title={title}
              label={label}
              height="100%"
              width="100%"
              {...cardTitle}
            />
          </LayoutChanger>
        </Section>
      </StyledCard>
    </StyledLink>
  )
}

export default FlexImageCard

FlexImageCard.strapiProps = {
  title: PropTypes.string,
  description: PropTypes.shape(RichText.strapiProps),
  image: PropTypes.object.isRequired,
}

FlexImageCard.propTypes = {
  ...FlexImageCard.strapiProps,
  cardBackground: PropTypes.oneOf(["none", "0", "1"]),
  cardShadow: PropTypes.bool,
  align: PropTypes.oneOf(["left", "center"]),
  link: PropTypes.shape(Link.strapiProps),
}

export const query = graphql`
  fragment FlexImageCard on Strapi_ComponentMoleculesFlexImageCard {
    title
    label
    backgroundImageMobile {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
    backgroundImageDesktop {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
    descriptionRichText {
      ...RichText
    }
    image {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    link {
      ...Link
    }
    button {
      ...Button
    }
  }
`
