import React from "react"
import { graphql } from "gatsby"

import ImageTextCarouselItem from "../molecules/ImageTextCarouselItem"
import GenericImageCarousel from "../molecules/GenericImageCarousel"

const ImageTextCarousel = (props) => {
  const {
    section,
    sectionTitle,
    items,
    imageTextCarouselImageLocation,
    imageLocation,
    showArrows,
    imageTextCarouselShowArrows,
    showIndicators,
    imageTextCarouselShowIndicators,
    controlsLocation,
    imageTextCarouselControlsLocation,
    textAlign,
    imageTextCarouselTextAlign,
    showImages,
    imageTextCarouselShowImages,
    rotateSpeed,
    imageTextCarouselRotateSpeed,
  } = props
  return (
    <GenericImageCarousel
      sectionTitle={sectionTitle}
      section={section}
      items={items}
      imageLocation={imageLocation || imageTextCarouselImageLocation}
      showImages={showImages || imageTextCarouselShowImages}
      controlsLocation={controlsLocation || imageTextCarouselControlsLocation}
      controls={showArrows || imageTextCarouselShowArrows}
      indicators={showIndicators || imageTextCarouselShowIndicators}
      rotateSpeed={rotateSpeed || imageTextCarouselRotateSpeed}
      textAlign={textAlign || imageTextCarouselTextAlign}
    />
  )
}

export default React.memo(ImageTextCarousel)

export const query = graphql`
  fragment ImageTextCarousel on Strapi_ComponentSectionsImageTextCarousel {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    items {
      ...ImageTextCarouselItem
    }
    imageTextCarouselImageLocation: imageLocation
    imageTextCarouselControlsLocation: controlsLocation
    imageTextCarouselShowImages: showImages
    imageTextCarouselShowArrows: showArrows
    imageTextCarouselShowIndicators: showIndicators
    imageTextCarouselTextAlign: textAlign
    imageTextCarouselRotateSpeed: rotateSpeed
  }
`
