import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import ThemeSwitcher from "../utils/ThemeSwitcher"
import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"
import FlexGrid from "../atoms/FlexGrid"

import PricingTier from "../molecules/PricingTier"
import SectionTitle from "../molecules/SectionTitle"

const Pricing = ({
  section,
  sectionTitle,
  flexGrid,
  tiers,
  tiersTheme,
  tiersBackground,
  largeTextColor,
  tiersShadow,
  tiersRadius,
  tiersAlign,
}) => {
  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
        </Reveal>
      )}
      <ThemeSwitcher theme={tiersTheme}>
        <FlexGrid {...flexGrid}>
          {tiers.map((tier, i) => (
            <PricingTier
              {...tier}
              largeTextColor={largeTextColor}
              align={tiersAlign}
              theme={tiersTheme}
              bg={tiersBackground !== null && `background.${tiersBackground}`}
              boxShadow={tiersShadow}
              borderRadius={tiersRadius}
              key={i}
            />
          ))}
        </FlexGrid>
      </ThemeSwitcher>
      {/* {button && (
          <Flex mt={8} flexDirection="column" alignItems="center">
            <Button {...button} />
          </Flex>
        )} */}
    </Section>
  )
}

export default Pricing

Pricing.propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  flexGrid: PropTypes.shape(FlexGrid.strapiProps),
  tiers: PropTypes.arrayOf(PropTypes.shape(PricingTier.strapiProps)),
  tiersAlign: PropTypes.oneOf(["left", "center"]).isRequired,
  tiersBackground: PropTypes.number.isRequired,
  tiersShadow: PropTypes.oneOf(["none", "sm", "md", "lg"]).isRequired,
  largeTextColor: PropTypes.oneOf(["text", "primary", "secondary"]).isRequired,
}

export const query = graphql`
  fragment Pricing on Strapi_ComponentSectionsPricing {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    tiers {
      ...PricingTier
    }
    flexGrid {
      ...FlexGrid
    }
    largeTextColor
    tiersTheme
    tiersBackground
    tiersShadow
    tiersRadius
    tiersAlign
  }
`
