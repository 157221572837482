import React, { useState, useEffect, useContext } from "react"
import { compose, color, space } from "styled-system"
import styled from "styled-components"
import css from "../../lib/styled-system/css"
import { ThemeContext } from "styled-components"

import Image from "../utils/Image"

import Section from "../atoms/Section"
import SectionTitle from "../molecules/SectionTitle"
import StandardPadding from "../atoms/StandardPadding"

import Icon from "../atoms/Icon"

import Box from "../atoms/Box"
import Card from "../atoms/Card"
import Flex from "../atoms/Flex"
import Indicator from "../atoms/Indicator"
import SliderControlArrow from "../icons/SliderControlArrow"
import ImageTextCarouselItem from "../molecules/ImageTextCarouselItem"
import Quote from "../molecules/Quote"

const StyledButton = styled("button")`
  ${compose(space, color)}
  svg {
    ${props => props.$flip && "transform: scaleX(-1);"}
  }
`
const StyledBox = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 0.4s ease;
`
// this seems to act more like a delay on the animation
const TRANSITION_LENGTH_MS = 50

export const Animation = styled(Box)`
  ${css({
    transitionDuration: "md",
    transitionTimingFunction: "ease",
  })}
  
  opacity: ${({ state }) =>
    state === "entering" || state === "entered" ? 0 : 1}; 
  `

export const OpacityTransition = styled(Box)`
  ${css({
    transitionDuration: "md",
    transitionTimingFunction: "ease",
  })}
`

const HALF_WIDTH = "48%"

const MAX_WIDTH_TEXT = 44

const GenericImageCarousel = ({
  items,
  sectionTitle,
  section,
  imageLocation,
  showImages,
  controlsLocation,
  textAlign,
  controls,
  indicators,
  rotateSpeed,
  quoteCarousel,
  card,
  icon,
}) => {
  const [activeIdx, setActiveIdx] = useState(0)
  const [transition, setTransition] = useState(false)
  // const [hasSlideImage, setHasSlideImage] = useState(!noImages)
  const { breakpoints } = useContext(ThemeContext)

  const centerControls = controlsLocation === "center"

  // offsets the controls visually into the text block
  const negativeControlsMargin = !(showImages && centerControls)

  if (card === null || card === undefined) {
    card = { useCard: false }
  }

  useEffect(() => {
    if (items.length > 1 && rotateSpeed) {
      let interval
      if (transition) {
        interval = setInterval(() => {
          setTransition(false)
        }, TRANSITION_LENGTH_MS)
        return () => {
          clearInterval(interval)
        }
      }
      return () => {
        clearInterval(interval)
      }
    }
  }, [transition, items, rotateSpeed])

  useEffect(() => {
    if (items.length > 1 && rotateSpeed) {
      let interval
      if (!transition) {
        interval = setInterval(() => {
          setActiveIdx((activeIdx + 1) % items.length)
          setTransition(true)
        }, rotateSpeed * 1000)
        return () => {
          clearInterval(interval)
        }
      }
    }
  }, [transition, items, rotateSpeed])

  function setActiveIndexHelper(idx) {
    setTransition(true)
    setActiveIdx(idx)
  }

  const Controls = () => {
    if (items.length <= 1) return null
    return (
      <Flex
        // className="control-container"
        position="relative"
        left="0px"
        bottom="0px"
        justifyContent={centerControls ? "center" : "flex-start"}
        alignItems="center"
        height={card?.useCard === true && centerControls ? 0 : 3}
        m={0}
        mt={
          ((imageLocation === "left" && controlsLocation === "left") ||
            (imageLocation === "right" && controlsLocation === "right")) &&
          6
        }
        // mt={{ _: 6, md: 3 }}
      >
        {controls && (
          <StyledButton
            $flip={true}
            mr={4}
            onClick={() => setActiveIndexHelper((activeIdx - 1) % items.length)}
            opacity={activeIdx === 0 ? 0.35 : 1}
            disabled={activeIdx === 0}
          >
            <SliderControlArrow direction="right" />
          </StyledButton>
        )}

        {indicators &&
          [...Array(items.length)].map((_item, idx) => (
            <Indicator
              onClick={() => setActiveIndexHelper(idx)}
              active={activeIdx === idx}
              ml={idx ? 3 : 0}
              idx={idx}
              key={idx}
            />
          ))}
        {controls && (
          <StyledButton
            ml={4}
            onClick={() => setActiveIndexHelper((activeIdx + 1) % items.length)}
            opacity={activeIdx === items.length - 1 ? 0.35 : 1}
            disabled={activeIdx === items.length - 1}
          >
            <SliderControlArrow direction="right" />
          </StyledButton>
        )}
      </Flex>
    )
  }

  return (
    <Section className="ImageTextCarousel" {...section} noStandardPadding>
      {items.map(({ backgroundImage, backgroundImageMobile }, idx) => {
        let images
        if (backgroundImageMobile && backgroundImage) {
          const breakpointTablet = parseInt(breakpoints.md)
          images = [
            { image: backgroundImage },
            {
              image: backgroundImageMobile,
              media: `(max-width: ${breakpointTablet}px)`,
            },
          ]
        }

        let image
        if (images) {
          image = (
            <Image
              alternativeText={backgroundImage.alternativeText}
              images={images}
              style={{ position: "static" }}
            />
          )
        } else if (backgroundImage) {
          image = <Image {...backgroundImage} style={{ position: "static" }} />
        } else if (backgroundImageMobile) {
          image = (
            <Image {...backgroundImageMobile} style={{ position: "static" }} />
          )
        }

        if (backgroundImage) {
          return (
            <OpacityTransition key={idx} opacity={activeIdx === idx ? 1 : 0}>
              <Box
                style={{ objectFit: "cover" }}
                position="absolute"
                top="0"
                right="0"
                bottom="0"
                left="0"
                overflow="hidden"
              >
                {image}
              </Box>
              <Box
                position="absolute"
                top="0"
                right="0"
                bottom="0"
                left="0"
                bg={`background.${section.bg}`}
                opacity={section.overlayOpacity || 0}
              />
            </OpacityTransition>
          )
        }
        return null
      })}
      <StandardPadding
        verticalPadding={section.verticalPadding}
        position="relative"
        zIndex={10}
        px={{ _: 5, xl: 0 }}
      >
        {sectionTitle && (
          <SectionTitle
            position="relative"
            zIndex={1}
            {...sectionTitle}
            maxWidth={42}
            mb={7}
          />
        )}
        <Card {...card}>
          <Box
            overflow="hidden"
            width="100%"
            py={card?.useCard === true ? 4 : null}
            px={card?.useCard === true ? 4 : null}
          >
            <Flex width="100%" py={null} alignItems={"center"}>
              {items.map(({ image, ...rest }, idx) => {
                const active = idx === activeIdx
                return (
                  <OpacityTransition
                    display="flex"
                    style={{
                      transform: `translateX(-${100 * idx}%)`,
                    }}
                    justifyContent="space-between"
                    zIndex={active ? 1 : -1}
                    opacity={active ? 1 : 0}
                    key={idx}
                    // className="carousel-container"
                    flexDirection={{
                      _: "column",
                      md:
                        showImages && imageLocation === "left"
                          ? "row"
                          : "row-reverse",
                    }}
                    width="100%"
                    flex="1 0 100%"
                    minHeight={
                      showImages && card?.useCard !== true
                        ? { _: 0, md: 20 }
                        : 0
                    }
                    alignItems={card?.useCard === true && "center"}
                  >
                    <Box
                      position="relative"
                      pb={!showImages ? 0 : { _: "70%", sm: "50%", md: 0 }}
                      height={!showImages ? 0 : { _: 0, md: "auto" }}
                      minHeight={!showImages ? 0 : { _: 0, md: "100%" }}
                      flex={
                        !showImages
                          ? "0 0 0"
                          : card?.useCard === true
                          ? { _: "0 0 0", md: `0 0 25%` }
                          : { _: "0 0 0", md: `0 0 ${HALF_WIDTH}` }
                      }
                      maxHeight={card?.useCard === true && "250px"}
                    >
                      <Box>
                        {image && (
                          <StyledBox
                            className="image-box"
                            maxHeight={"400px"}
                            key={idx}
                            active={idx === activeIdx}
                          >
                            <Image
                              style={{ position: "initial" }}
                              imgStyle={{
                                objectFit: "contain",
                                objectPosition: "top center",
                              }}
                              position="absolute"
                              {...image}
                            />
                          </StyledBox>
                        )}
                        {/* {controlsLocation === "left" &&
                        imageLocation === "left" && <Controls />} */}
                      </Box>
                    </Box>
                    <Box
                      // className="carousel-text-container"
                      display="flex"
                      flexDirection="column"
                      alignItems={
                        showImages && imageLocation === "left"
                          ? "flex-end"
                          : "flex-start"
                      }
                      mt={showImages ? { _: 5, md: 0 } : 0}
                      width="100%"
                      pb={negativeControlsMargin ? { _: 0, md: 7 } : 0}
                      flex={
                        !showImages
                          ? "0 0 100%"
                          : card?.useCard === true
                          ? { _: "0 0 0", md: `0 0 70%` }
                          : { _: "0 0 0", md: `0 0 ${HALF_WIDTH}` }
                      }
                    >
                      <Box
                        display="flex"
                        position="relative"
                        height={{ _: "100%" }}
                        flexDirection="column"
                        justifyContent={{ _: "space-between" }}
                        width="100%"
                        maxWidth={
                          !showImages || card?.useCard === true
                            ? "none"
                            : { _: "none", md: 32 }
                        }
                        mx={!showImages && textAlign === "center" ? "auto" : 0}
                        // minHeight={showImages ? { _: 0, md: 23 } : 0}
                        ml={
                          showImages && imageLocation === "left"
                            ? { _: 0, md: 5 }
                            : {}
                        }
                        mr={
                          showImages && imageLocation === "right"
                            ? { _: 0, md: 5 }
                            : {}
                        }
                      >
                        {quoteCarousel && (
                          <Flex
                            justifyContent={"flex-start"}
                            alignItems={"flex-start"}
                          >
                            {icon && (
                              <Box mr={6}>
                                <Icon
                                  {...icon}
                                  width={"40px"}
                                  height={"40px"}
                                />
                              </Box>
                            )}
                            <Quote
                              maxWidth={MAX_WIDTH_TEXT}
                              textAlign={textAlign}
                              noMarginBottom={card?.useCard && true}
                              {...rest}
                            />
                          </Flex>
                        )}
                        {!quoteCarousel && (
                          <ImageTextCarouselItem
                            maxWidth={MAX_WIDTH_TEXT}
                            textAlign={textAlign}
                            {...rest}
                          />
                        )}
                      </Box>
                    </Box>
                  </OpacityTransition>
                )
              })}
            </Flex>
            {(controls || indicators) && (
              <Flex
                ml={
                  controlsLocation === "left" &&
                  showImages &&
                  imageLocation === "left"
                    ? "auto"
                    : 0
                }
                position="relative"
                zIndex={1}
                width={
                  showImages &&
                  card?.useCard !== true &&
                  ((controlsLocation === "right" &&
                    imageLocation === "right") ||
                    (controlsLocation === "left" && imageLocation === "left"))
                    ? { _: "100%", md: HALF_WIDTH }
                    : "100%"
                }
                maxWidth={
                  controlsLocation === "right" &&
                  !showImages &&
                  textAlign !== "center"
                    ? MAX_WIDTH_TEXT
                    : "none"
                }
                mt={negativeControlsMargin ? { _: 3, md: -6 } : 3}
                justifyContent={
                  centerControls
                    ? "center"
                    : controlsLocation === "left"
                    ? "flex-start"
                    : "flex-end"
                }
              >
                <Controls />
              </Flex>
            )}
          </Box>
        </Card>
      </StandardPadding>
    </Section>
  )
}

export default React.memo(GenericImageCarousel)
